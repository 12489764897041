<template>
  <section class="contact-us-pg">
    <h2 class="desc-block">
      {{ $t("contact_us.title") }}
    </h2>
    <p class="title_mini__modal" style="max-width: 70%;">
      {{ $t("contact_us.comments") }}
    </p>
    <div class="form">
      <div :class="['input-form', { invalide: errors.name }]">
        <span>{{ $t("contact_us.name") }}</span>
        <input
          v-model="form.name"
          :placeholder="$t('contact_us.enter_your_name')"
          type="text"
        />
        <span v-if="errors.name" class="invalide_text">
          {{ errors.name[0] }}
        </span>
      </div>

      <div :class="['input-form', { invalide: errors.phone }]">
        <span>{{ $t("register.phone") }}</span>
        <input v-model="form.phone" type="text" />
        <span v-if="errors.phone" class="invalide_text">
          {{ errors.phone[0] }}
        </span>
      </div>

      <div :class="['input-form', { invalide: errors.email }]">
        <span>{{ $t("login.email") }}</span>
        <input
          v-model="form.email"
          :placeholder="$t('login.enter_your_email')"
          type="email"
        />
        <span v-if="errors.email" class="invalide_text">
          {{ errors.email[0] }}
        </span>
      </div>

      <div :class="['input-form', { invalide: errors.subject }]">
        <span>{{ $t("contact_us.subject") }}</span>
        <input
          v-model="form.subject"
          :placeholder="$t('contact_us.enter_the_subject')"
          type="text"
        />
        <span v-if="errors.subject" class="invalide_text">
          {{ errors.subject[0] }}
        </span>
      </div>

      <div :class="['input-form', { invalide: errors.message }]">
        <span>{{ $t("contact_us.message") }}</span>
        <textarea
          v-model="form.message"
          class="textarea_modal"
          style="background-color: #FFFFFF"
        />
        <span v-if="errors.message" class="invalide_text">
          {{ errors.message[0] }}
        </span>
      </div>

      <div class="input-form" style="align-items: center; margin-top: 13px">
        <input
          v-if="!sent"
          :value="$t('contact_us.submit')"
          type="submit"
          style="width: 100%"
          @click="submit"
        />
        <div v-else class="btn_home__404" style="border-top: none">
          <router-link :to="{ name: 'home' }">
            {{ $t("back_to_homepage") }}
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        phone: "",
        email: "",
        subject: "",
        message: ""
      },
      sent: false,
      errors: []
    };
  },

  methods: {
    submit() {
      this.errors = [];

      this.$http
        .post("/api/contact-us", this.form)
        .then(() => {
          this.form.name = "";
          this.form.phone = "";
          this.form.email = "";
          this.form.subject = "";
          this.form.message = "";
          this.sent = true;
        })
        .catch(error => (this.errors = error.response.data.errors));
    }
  },

  metaInfo() {
    return {
      title: this.$t("seo.contact_us.title"),
      meta: [
        {
          name: "description",
          content: this.$t("seo.contact_us.description")
        },
        {
          name: "keywords",
          content: this.$t("seo.contact_us.keywords")
        }
      ]
    };
  }
};
</script>
